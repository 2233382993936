import { Container, Row, Col } from "react-bootstrap";

// components
import ToDoForm from "./components/ToDoForm";
import ToDoWrapper from "./components/ToDoWrapper";

function App() {

  return (
    <Container className="py-4">
      <Row>
        <Col md={6}>
          {/* Todo Form */}
          <ToDoForm />
        </Col>
        <Col md={6}>
          <ToDoWrapper />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
