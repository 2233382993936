import { memo } from "react";
import { Col, Form, Row } from "react-bootstrap";

const ToDoFilters = ({ onSearchChange, onOrderChange }) => {
    console.log("rendered");
    return (
        <Row className="mb-4">
            <Col md={8}>
                <Form.Control 
                    type="text" 
                    onChange={(e) => onSearchChange(e.target.value)} 
                    placeholder="Search ..." 
                />
            </Col>
            <Col md={4}>
                <Form.Select
                    onChange={(e) => onOrderChange(e.target.value)}
                    aria-label="Default select example"
                >
                    <option>order by</option>
                    <option value="desc">Latest</option>
                    <option value="asc">Oldest</option>
                </Form.Select>
            </Col>
        </Row>
    )
}

export default memo(ToDoFilters);